import React from "react"
import {
  GlobeAltIcon,
  LightningBoltIcon,
  ScaleIcon,
} from "@heroicons/react/outline"

const features = [
  {
    name: "Cheap pricing",
    description:
      "Subscription pricing at just 3.99€ per month, granting you seamless connectivity between your bank accounts and YNAB. With this subscription, you'll enjoy the convenience of once-per-hour transaction syncing, ensuring your finances are always up to date. Say goodbye to manual input and hello to effortless budget management with YNAB's streamlined syncing service. Get started today and take control of your financial future for less than the price of a cup of coffee each month.",
    icon: GlobeAltIcon,
  },
  {
    name: "No hidden fees",
    description:
      "At BudgetSyncer, we prioritize your freedom of choice. Should you ever decide to discontinue your subscription, rest assured that canceling is simple and hassle-free. Not only will your subscription be promptly terminated, but all your data will be securely erased from our systems. No extra fees, no hidden charges—just seamless service tailored to your needs.",
    icon: ScaleIcon,
  },
  {
    name: "Syncing seamlessly every hour",
    description:
      "Experience the convenience of streamlined financial management! With our service, when your bank account detects a new transaction, it's swiftly entered or matched to your YNAB account, ensuring up-to-date records. Plus, enjoy the peace of mind that comes with once-per-hour synchronization. Stay in control of your finances effortlessly, with every transaction seamlessly accounted for in your YNAB account.",
    icon: LightningBoltIcon,
  },
]

export default function Offering() {
  return (
    <div className="py-12 bg-white">
      <div className="max-w-xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8">
        <h2 className="sr-only">A better way to send money.</h2>
        <dl className="space-y-10 lg:space-y-0 lg:grid lg:grid-cols-3 lg:gap-8">
          {features.map(feature => (
            <div key={feature.name}>
              <dt>
                <div className="flex items-center justify-center h-12 w-12 rounded-md bg-sky-300 text-white">
                  <feature.icon className="h-6 w-6" aria-hidden="true" />
                </div>
                <p className="mt-5 text-lg leading-6 font-medium text-gray-900">
                  {feature.name}
                </p>
              </dt>
              <dd className="mt-2 text-base text-gray-500">
                {feature.description}
              </dd>
            </div>
          ))}
        </dl>
      </div>
    </div>
  )
}
