import * as React from "react"

import Layout from "../components/layout-2"
import MainHeader from "../components/main-header";
import CtaSection from "../components/cta-section";
import Offering from "../components/offering";
import Pricing from "../components/Pricing";
import Seo from '../components/seo'

const IndexPage = () => (
    <Layout path="/" userLoggedIn={false}>
        <Seo title="Budgetsyncer" />
        <MainHeader/>
        <CtaSection/>
        <Offering/>
        <Pricing/>
    </Layout>
)

export default IndexPage
