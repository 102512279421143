import React from "react"
import { ExternalLinkIcon } from '@heroicons/react/solid'

export default function CtaSection() {
  return (
    <div className="relative bg-lime-300">
      <div className="h-56 bg-main sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2">
        <img
          className="w-full h-full object-cover"
          src="https://images.unsplash.com/photo-1507207611509-ec012433ff52?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=934&q=80"
          alt=""
        />
      </div>
      <div className="relative max-w-7xl mx-auto px-4 py-12 sm:px-6 lg:px-8 lg:py-16">
        <div className="md:ml-auto md:w-1/2 md:pl-10">
          <h2 className="text-base font-semibold uppercase tracking-wider text-gray-900">
            Make bugdeting fun again
          </h2>
          <p className="mt-2 text-gray-900 text-3xl font-extrabold tracking-tight sm:text-4xl">
            We’re here to help
          </p>
          <p className="mt-3 text-lg text-gray-900">

            Say goodbye to the hassle of manually tracking and matching your budget transactions! With our product, effortlessly connect your bank to YNAB and let us handle the matching for you. Eliminate the stress of unaligned balances and embrace a smoother budgeting experience. Start today and unlock the full potential of YNAB, leaving you more time to relax and enjoy the financial freedom it brings.
          </p>
          <div className="mt-8">
            <div className="inline-flex rounded-md shadow">
              <a
                href="/own-connections"
                className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-gray-900 bg-white hover:bg-gray-50"
              >
                Start now
                <ExternalLinkIcon
                  className="-mr-1 ml-3 h-5 w-5 text-gray-500"
                  aria-hidden="true"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}