import React from "react"
import { Link } from 'gatsby'

export default function MainHeader() {
  return (
    <div className="bg-white">
      <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
        <div className="text-center">
          <p className="pb-8 mt-1 text-4xl font-extrabold text-gray-700 sm:text-4xl sm:tracking-tight lg:text-4xl">
            BudgetSyncer: Simplify Finances with {" "}
            <a
              className="text-indigo-600 hover:text-indigo-900"
              href="https://www.youneedabudget.com/"
            >
              YNAB!
            </a>
            .
          </p>
          <p className="max-w-xl mt-5 mx-auto text-xl text-gray-500">
            BudgetSyncer makes budgeting easy by automatically updating your YNAB budget whenever you spend money. It's quick, secure, and means less work for you, so you can focus on saving and spending smarter.
          </p>
          <p className="max-w-xl mt-5 mx-auto text-xl text-gray-500">
            Budgetsyncer is free for the first week, giving you plenty of time to explore its features and benefits. After that, it's just <span className="text-lime-600">3.99€</span> to keep enjoying all the perks!
          </p>
          <p className="max-w-xl mt-5 mx-auto text-xl text-gray-500">
            <Link className="text-indigo-600 hover:text-indigo-900" to="/banks">Check here all the supported banks</Link>
          </p>
        </div>
      </div>
    </div>
  )
}
